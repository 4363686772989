import React from 'react';

import { styled } from '@tmap/mmm-style-guide/src/styled';
import GlobalFooter from '@tmap/mmm-style-guide/src/GlobalFooter';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import MmmLogo from '@tmap/mmm-style-guide/src/svg/MmmLogo';
import { DARK_THEME } from '../lib/themes';

const FOOTER_BASE_URL = ''; // www site is already hosted at base url
const TermsLink = 'https://app.termly.io/document/terms-and-conditions/293fa8f8-8d9f-42d7-b5b9-f8b407bea839';
const PrivacyPolicyLink = 'https://app.termly.io/document/privacy-policy/6591afef-d955-453b-bb9c-a0a855271f8b';
const ContactUsLink = `${FOOTER_BASE_URL}/contact-us`;
const OffersLink = `${FOOTER_BASE_URL}/get-paid`;
const CommunitiesLink = `${FOOTER_BASE_URL}/moving-living`;
const ArticlesLink = `${FOOTER_BASE_URL}/articles`;
const HiwLink = `${FOOTER_BASE_URL}/how-it-works`;
const PressLink = `${FOOTER_BASE_URL}/news`;
const NursesLink = `${FOOTER_BASE_URL}/articles/nurse-signing-bonuses-in-the-united-states`;
const PoliceLink = `${FOOTER_BASE_URL}/articles/police-officer-signing-bonuses-in-the-united-states`;
const TeachersLink = `${FOOTER_BASE_URL}/articles/teacher-signing-bonuses-in-the-united-states`;
const CommunityArticlesLink = `${FOOTER_BASE_URL}/community-articles`;

const footerArgs = {
  logo: () => (<MmmLogo size={22} />),
  logoDark: () => (
    <MmmLogo
      size={22}
      colors={{
        text: 'common.white',
        ring: 'common.white',
      }}
    />
  ),
  columns: [
    {
      columnName: 'General',
      links: [
        { label: 'Contact Us', url: ContactUsLink },
        { label: 'Terms and Conditions', url: TermsLink },
        { label: 'Privacy Policy', url: PrivacyPolicyLink },
      ],
    },
    {
      columnName: 'Browse',
      links: [
        { label: 'Programs', url: OffersLink },
        { label: 'Communities', url: CommunitiesLink },
        { label: 'Nurse Signing Bonuses', url: NursesLink },
        { label: 'Police Officer Signing Bonuses', url: PoliceLink },
        { label: 'Teacher Signing Bonuses', url: TeachersLink },
      ],
    },
    {
      columnName: 'Resources',
      links: [
        { label: 'Articles', url: ArticlesLink },
        { label: 'How it Works', url: HiwLink },
        { label: 'Press', url: PressLink },
        { label: 'For Economic Developers', url: CommunityArticlesLink },
      ],
    },
  ],
};

const ThemedFooter = styled(GlobalFooter)(({ theme }) => ({
  margin: 0,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  backgroundColor: (theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.veryDark),
  paddingLeft: theme.spacing(11),
  paddingRight: theme.spacing(11),
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

function Footer(props) {
  const { theme = DARK_THEME } = props;
  const ThemeWrapper = theme ? ThemeProvider : React.Fragment;
  const themeWrapperProps = theme ? { theme } : {};
  return (
    <ThemeWrapper {...themeWrapperProps}>
      <ThemedFooter {...props} {...footerArgs} />
    </ThemeWrapper>
  );
}

export default Footer;
