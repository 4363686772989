import makeView from '@tmap/mmm-core/redux/views/makeView';
import makeThunktankAction from '@tmap/mmm-core/redux/thunktank/makeThunktankAction';

import { getClient } from '../lib/apiClient';
import { TASKS_CACHE } from './cacheIndex';

export const getApplicationStatus = makeView(
  async (userListItemIds, userId, enrich = false) => {
    if (userListItemIds.length === 0 || !userId) {
      return { docs: [] };
    }
    try {
      const docs = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/leads/status/findByUserListItemIds', {
        userListItemIds,
        enrich,
      })).data;
      return ({ docs });
    } catch {
      return ({ docs: [] });
    }
  },
);

export const getLeadTasks = makeView(
  async (leadId) => {
    if (!leadId) {
      return { docs: [] };
    }
    const docs = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/tasks/findMoverTasksByLead', {
      leadId,
    })).data;
    return ({ docs });
  },
).withCache(TASKS_CACHE);

export const markTaskComplete = makeThunktankAction(async (taskId, markComplete) => {
  const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/tasks/markComplete', {
    taskId,
    markComplete,
  });
  return res.data;
}).withCache(TASKS_CACHE);

export const uploadAttachment = makeThunktankAction(async ([file], task, profileId, leadId) => {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  formData.append('leadId', leadId);
  formData.append('userProfileId', profileId);
  formData.append('uploadType', task.typeOptions?.uploadType || 'other');
  formData.append('file', file);
  formData.append('taskId', task._id);
  const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/attachments/upload', formData);
  const [attachment] = result.data;
  return {
    ...task,
    // mark task complete after upload
    completedAt: new Date(),
    attachments: [...(task.attachments || []), attachment],
  };
}).withPartialCache(TASKS_CACHE);

export const deleteAttachment = makeThunktankAction(async (attachmentId, task) => {
  await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).delete(`/api/v1/attachments/${attachmentId}`);
  return {
    ...task,
    attachments: task.attachments.filter((a) => a._id !== attachmentId),
  };
}).withPartialCache(TASKS_CACHE);
