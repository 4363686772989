import React, { useMemo, useRef } from 'react';

import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Tooltip } from '@tmap/mmm-style-guide/src/Tooltip';
import { Chip } from '@tmap/mmm-style-guide/src/Chip';
import { RocketLaunch } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import locationLabelText from '@tmap/mmm-core/locationLabelText';
import { toKey, types } from '@tmap/mmm-core/entityKey';
import { LeadStatus, leadStatusDetails } from '@tmap/mmm-core/leads/status';

import LinkCard from '../linkCard';
import ResponsivePicture from '../responsivePicture';
import findImpressionFeature from '../../lib/findImpressionFeature';
import { getTagPills } from '../../lib/tags';
import { offerTypeFromValue } from '../../lib/filterTypes';
import getPrimaryIncentiveImage from '../../lib/getPrimaryIncentiveImage';
import useImpressions, { ImpressionTracker } from '../../hooks/useImpressions';

const CardContent = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    flexFlow: 'column nowrap',
    height: '100%',
  },
}));

const ContentPlacement = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexGrow: '1',
  padding: theme.spacing(0, 0, 0, 5),
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 0, 0, 0),
  },
}));

const DetailsGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  height: '40px',
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    paddingRight: theme.spacing(3),
  },
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
}));

const ImageBox = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexShrink: '0',
  width: '246px',
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ButtonBox = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  textAlign: 'right',
  width: '100%',
  '& .MuiButton-root': {
    width: '176px',
    padding: theme.spacing(0.75, 6),
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiButton-root': {
      width: '152px',
      padding: theme.spacing(0.75, 3),
    },
  },
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    height: '100%',
    '& .MuiButton-root': {
      width: '100%',
    },
  },
}));

const CardButton = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  '& .MuiButton-root': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const IncompleteTasksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.grey[100],
  padding: theme.spacing(1, 2.5, 1, 1.5),
  borderRadius: '4px',
  minHeight: '36.5px',
  '&::before': {
    content: '""',
    width: '8px',
    height: '8px',
    flexShrink: 0,
    marginRight: theme.spacing(1),
    borderRadius: '8px',
    background: theme.palette.error.main,
  },
}));

function OfferCard(props) {
  const {
    offerId,
    incentive,
    lead,
    offerProgressSettings: { steps },
    shouldLazyLoad,
    lazyProps,
    placementId,
    pageId,
    vertical = false,
  } = props;

  const incentiveUrl = `/myprofile/offers/${encodeURIComponent(offerId)}`;
  const primaryImage = getPrimaryIncentiveImage(incentive);
  const altText = `Get paid to live in 
    ${locationLabelText(incentive.locationText, incentive.regionText)}`;

  const [valueIcons, plusMore] = useMemo(() => {
    const valueTypes = incentive?.values?.map((value) => value.valueType) || [];
    const deduped = Array.from(new Set(valueTypes));
    const allIcons = deduped.map(offerTypeFromValue);
    const MAX_ICONS = 3;
    if (allIcons.length > MAX_ICONS) {
      return ([
        allIcons.slice(0, MAX_ICONS - 1),
        {
          title: allIcons.slice(MAX_ICONS - 1).map((icon) => icon.title).join(', '),
          count: allIcons.length - MAX_ICONS + 1,
        },
      ]);
    }
    return [allIcons, null];
  }, [incentive.values]);

  const impressionMeta = useMemo(() => ({ pageId }), [pageId]);
  const { trackView, trackClick } = useImpressions(
    toKey(types.INCENTIVE, incentive._id),
    placementId,
    impressionMeta,
  );

  const baseElement = useRef();
  const favoriteTarget = useRef();
  const applyTarget = useRef();

  const handleImpressionClick = (event) => {
    const elementsToTrack = new Map([
      [favoriteTarget.current, 'favorite'],
      [applyTarget.current, 'apply'],
      [baseElement.current, 'base'],
    ]);

    const featureName = findImpressionFeature(event, elementsToTrack);
    trackClick({ feature: featureName });
  };

  const currentStep = useMemo(() => (
    steps.find((step) => step.statuses.includes(lead.status[0].status))
  ), [steps, lead]);

  const currentStatus = useMemo(() => (
    leadStatusDetails.find((status) => (
      status.value === lead.status[0].status
    ))
  ), [lead]);

  const highlightStatus = useMemo(() => {
    const offeredIndex = leadStatusDetails.findIndex((status) => (
      status.value === LeadStatus.OFFERED
    ));
    return leadStatusDetails.findIndex((enumStatus) => (
      enumStatus.value === currentStatus.value
    )) >= offeredIndex;
  }, [currentStatus.value]);

  return (
    <ImpressionTracker
      trackView={trackView}
      onClickCapture={handleImpressionClick}
      ref={baseElement}
    >
      <LinkCard href={incentiveUrl} alt={altText} sx={{ height: '100%' }}>
        <CardContent vertical={vertical}>
          <ImageBox vertical={vertical}>
            <Box sx={{ position: 'relative' }}>
              <LinkCard.Overlays sx={(theme) => ({ padding: theme.spacing(1, 1.5) })}>
                <LinkCard.PillBar>{incentive.tags && (
                  getTagPills(incentive.tags).map(({ label, icon, iconColor }, i) => (
                    <LinkCard.Pill
                      key={`offer-${incentive._id}-pill-${i}`}
                      label={label}
                      icon={icon}
                      iconColor={iconColor}
                    />
                  ))
                )}
                </LinkCard.PillBar>
                <LinkCard.FavoriteButton entity={incentive} ref={favoriteTarget} />
              </LinkCard.Overlays>
              <LinkCard.Image
                borderRadius='8px'
                dimTop
                shouldLazyLoad={shouldLazyLoad}
                lazyProps={lazyProps}
              >
                <ResponsivePicture
                  image={primaryImage}
                  width={246}
                  height={200}
                  xs={1.5}
                  md={2}
                  xl={4}
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: 'inherit',
                  }}
                />
              </LinkCard.Image>
            </Box>
          </ImageBox>
          <ContentPlacement vertical={vertical}>
            <Grid
              container
              sx={{
                height: { xs: '100%', md: vertical ? '100%' : 'auto' },
                flexDirection: { xs: 'column', md: vertical ? 'column' : 'row' },
              }}
            >
              {currentStatus && (
                <Grid item xs='auto' md={vertical ? 'auto' : 12} mb={1} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                  <Chip
                    size='small'
                    variant='outlined'
                    label={currentStatus.title}
                    sx={{
                      color: (theme) => (
                        highlightStatus
                          ? theme.palette.secondary.dark
                          : theme.palette.grey[600]
                      ),
                      border: (theme) => (
                        highlightStatus
                          ? `1px solid ${theme.palette.secondary.dark}`
                          : `1px solid ${theme.palette.grey[600]}`
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs='auto' md={vertical ? 'auto' : 12} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
                  {incentive.title || 'Total Value'}
                </Typography>
              </Grid>
              <Grid item xs='auto' mb={2} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                <DetailsGrid container vertical={vertical} width='min(100%, 200px)'>
                  <Grid item minWidth={80}>
                    <Typography fontSize='16px'>
                      {incentive.totalValue ? `$${new Intl.NumberFormat().format(incentive.totalValue)}+` : ''}
                    </Typography>
                  </Grid>
                  <Grid item minWidth={80}>
                    <Box sx={{ position: 'relative', top: '1px' }}>
                      <Grid container spacing={1} justifyContent='end'>
                        {valueIcons.map(({ icon: ValueIcon, title }, i) => (
                          <Grid item key={`offer-${incentive._id}-value-${i}`}>
                            <Tooltip title={title} aria-label={`incentive value: ${title}`}>
                              <ValueIcon fontSize='13px' />
                            </Tooltip>
                          </Grid>
                        ))}
                        {plusMore && (
                          <Grid item>
                            <Tooltip title={plusMore.title} aria-label={`more incentive values: ${plusMore.title}`}>
                              <Typography variant='body2' fontSize='13px'>
                                +{plusMore.count}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </DetailsGrid>
              </Grid>
              {lead.incompleteTasks ? (
                <Grid item xs md={vertical ? true : 12}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs md={vertical ? true : 'auto'} sx={{ maxWidth: '100%!important' }}>
                      <IncompleteTasksBox>
                        <Typography variant='body2'>
                          You have incomplete tasks that need attention
                        </Typography>
                      </IncompleteTasksBox>
                    </Grid>
                    <Grid item xs={12} md={vertical ? 12 : 'auto'}>
                      <CardButton
                        color='primary'
                        variant='contained'
                        fullWidth
                        startIcon={<RocketLaunch />}
                        ref={applyTarget}
                      >
                        Get&nbsp;Started
                      </CardButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs>
                  <ButtonBox vertical={vertical}>
                    <CardButton
                      variant='contained'
                      disableElevation
                      fullWidth
                      color='primary'
                      ref={applyTarget}
                    >
                      {currentStep?.buttonText || 'Review Program'}
                    </CardButton>
                  </ButtonBox>
                </Grid>
              )}
            </Grid>
          </ContentPlacement>
        </CardContent>
      </LinkCard>
    </ImpressionTracker>
  );
}

export default OfferCard;
