import React, {
  useEffect, useMemo, useRef,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Skeleton } from '@tmap/mmm-style-guide/src/Skeleton';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import { fromKey, toKey, types } from '@tmap/mmm-core/entityKey';
import useView from '@tmap/mmm-core/redux/views/useView';
import { UserListItemStatus } from '@tmap/mmm-core/commonApp/userListItemStatus';
import { LeadStatus } from '@tmap/mmm-core/leads/status';

import ColorBlocks from '../../layouts/colorBlocks';
import { getIncentives } from '../../actions/incentive';
import { getApplicationStatus } from '../../actions/offerDetails';
import useCurrentUser from '../../hooks/useCurrentUser';
import OfferCard from '../incentiveCard/applied';
import ActiveOffersCTA from './activeOffersCTA';

const ContentContainer = styled(Box)(({ theme }) => ({
  borderWidth: '2px 0px 2px 0px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
}));

const WorkspaceButton = styled(Button)(({ theme }) => ({
  '& button': {
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 2.75),
  },
}));

const contentSlideStyle = { maxWidth: '328px', height: 'auto' };

function ActiveOfferCard({ offer, settings, index }) {
  const { _id, incentive, lead } = offer;
  return (
    <OfferCard
      offerId={_id}
      incentive={incentive}
      lead={lead}
      offerProgressSettings={settings}
      placementId={`homepage-activeOffers-${index}`}
      shouldLazyLoad={index > 3}
      pageId='homepage'
      color='secondary'
      variant='contained'
      disableElevation
      sx={{
        '& .MuiButton-root': {
          whiteSpace: 'nowrap',
        },
      }}
      vertical
    />
  );
}

function ActiveOffersBlock({ offerProgressSettings }) {
  const favorites = useSelector((state) => state?.users?.favorites || [], shallowEqual);
  const favoritesReady = useSelector((state) => state?.users?.favoriteInit, shallowEqual);

  const saved = useMemo(() => favorites.reduce((groups, current) => {
    const { type, id } = fromKey(current);
    return { ...groups, [type]: [...(groups?.[type] || []), id] };
  }, {
    incentive: [],
    community: [],
    contribution: [],
    length: favorites.length,
  }), [favorites]);

  const user = useCurrentUser();
  const userIncentives = useSelector((state) => state?.incentives?.incentives, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (favoritesReady) {
      dispatch(getIncentives(saved.incentive.map((id) => toKey(types.INCENTIVE, id))));
    }
  }, [favoritesReady, saved, dispatch]);

  const favoriteListItems = useSelector((state) => (
    state?.users?.favoriteListItems || {}
  ), shallowEqual);
  const appliedIncentives = useMemo(() => (
    Object.entries(favoriteListItems)
      .filter(([, { itemKey, status }]) => (
        itemKey
        && fromKey(itemKey).type === types.INCENTIVE
        && [UserListItemStatus.ACTIVE, UserListItemStatus.APPLIED].includes(status)
      ))
      .map(([id, values]) => ({ _id: id, ...values }))
  ), [favoriteListItems]);

  const [leads, { isLoading: activeOffersLoading }] = useView('my-offers', getApplicationStatus(...useMemo(() => ([
    appliedIncentives.map((i) => i._id),
    user.userId,
  ]), [appliedIncentives, user.userId])));

  const activeOffers = useMemo(() => (
    appliedIncentives.map((uli, i) => ({
      ...uli,
      lead: leads[i],
      incentive: userIncentives[uli.itemKey.split(':')[1]],
    })).filter((offer) => (
      offer.lead
      && offer.incentive
      && ![
        LeadStatus.DECLINED,
        LeadStatus.NEW,
      ].includes(offer.lead.status[0].status)
    )).sort((o1, o2) => {
      if (o1.lead.incompleteTasks && !o2.lead.incompleteTasks) {
        return -1;
      }
      if (!o1.lead.incompleteTasks && o2.lead.incompleteTasks) {
        return 1;
      }
      return 0;
    })
  ), [appliedIncentives, userIncentives, leads]);

  const workspaceSlideStyle = useMemo(() => ({
    maxWidth: '328px',
    height: 'auto',
    display: 'flex',
  }), []);

  const swiperRef = useRef(null);
  if (!activeOffersLoading && activeOffers?.length === 0) {
    return null;
  }

  // Display number of offers in the header if > 3, but only on desktop
  const optionalQuantity = activeOffers?.length > 3 ? activeOffers.length : '';
  const desktopLabel = user?.firstName ? `${user.firstName}, catch up on ${optionalQuantity} active programs and tasks` : `Catch up on ${optionalQuantity} active programs and tasks`;
  const mobileLabel = user?.firstName ? `${user.firstName}, catch up on active programs and tasks` : 'Catch up on active programs and tasks';

  return (
    <ContentContainer>
      <ColorBlocks.Block color='light' overflow>
        {/* Desktop block - only show on lg or higher (or md if only one offer) */}
        <Box sx={{ display: { xs: 'none', md: activeOffers.length === 1 ? 'block' : 'none', lg: 'block' } }}>
          {activeOffersLoading && (
            <Grid container spacing={2}>
              {[1, 2, 3].map((i) => (
                <Grid item xs={4} key={`placeholder-${i}`}>
                  <Skeleton variant='rectangular' width='100%' height={450} />
                </Grid>
              ))}
            </Grid>
          )}
          {(!activeOffersLoading && activeOffers?.length > 0) && (
            <>
              <Grid container spacing={2} sx={{ mb: 6 }}>
                <Grid item xs>
                  <Typography
                    fontWeight={800}
                    color='text.primary'
                    fontSize='28px'
                    sx={{ lineHeight: '34.58px', mb: 1.5 }}
                  >
                    {desktopLabel}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    fontWeight={400}
                    fontSize='16px'
                  >
                    Ready, Set, Go: Dive into your Programs with Pending Tasks:
                  </Typography>
                </Grid>
                {activeOffers.length > 2 && (
                  <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <WorkspaceButton
                      variant='outlined'
                      color='inverted'
                      href='/myprofile/offers'
                    >
                      {activeOffers.length === 3 ? 'My Workspace' : `See all ${activeOffers.length} programs`}
                      <ArrowForward sx={{ ml: 1.5 }} />
                    </WorkspaceButton>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                {activeOffers.slice(0, 3).map((offer, i) => (
                  <Grid item xs={activeOffers.length === 1 ? 6 : 4}>
                    <ActiveOfferCard offer={offer} settings={offerProgressSettings} index={i} />
                  </Grid>
                ))}
                {activeOffers.length < 3 && (
                  <Grid item xs={activeOffers.length === 1 ? 6 : 4}>
                    <ActiveOffersCTA />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
        {/* Mobile/tablet block - show on < lg, or < md if only one offer */}
        <Box sx={{ display: { xs: 'block', md: activeOffers?.length === 1 ? 'none' : 'block', lg: 'none' } }}>
          {!(activeOffersLoading && activeOffers?.length > 0) && (
            <>
              <Typography
                fontWeight={800}
                color='text.primary'
                fontSize='28px'
                sx={{ lineHeight: '34.58px', mb: 1.5 }}
              >
                {mobileLabel}
              </Typography>
              <Typography
                variant='body1'
                color='text.secondary'
                fontWeight={400}
                fontSize='16px'
                sx={{ mb: 6 }}
              >
                Ready, Set, Go: Dive into your Programs with Pending Tasks:
              </Typography>
            </>
          )}
          <Swiper ref={swiperRef} slidesPerView='auto' spaceBetween={16}>
            {(activeOffersLoading) && [1, 2, 3].map((i) => (
              <SwiperSlide key={`placeholder-${i}`} style={contentSlideStyle}>
                <Skeleton variant='rectangular' width={328} height={434} />
              </SwiperSlide>
            ))}
            {activeOffers?.map((offer, i) => (
              <SwiperSlide key={offer._id} style={contentSlideStyle}>
                <ActiveOfferCard offer={offer} settings={offerProgressSettings} index={i} />
              </SwiperSlide>
            ))}
            <SwiperSlide style={workspaceSlideStyle}>
              <ActiveOffersCTA />
            </SwiperSlide>
          </Swiper>
        </Box>
      </ColorBlocks.Block>
    </ContentContainer>
  );
}

export default ActiveOffersBlock;
