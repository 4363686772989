import React, {
  useContext,
} from 'react';
import Link from 'next/link';

import MmmLogo from '@tmap/mmm-style-guide/src/svg/MmmLogo';
import { AppBar } from '@tmap/mmm-style-guide/src/AppBar';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Toolbar } from '@tmap/mmm-style-guide/src/Toolbar';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { MenuIcon } from '@tmap/mmm-style-guide/src/Icon';

import HeaderContext from './HeaderContext';
import NavButton from './navButton';
import { SignInSignUp } from './authButtons';
import HeaderDrawer from './headerDrawer';
import HeaderMenu from './headerMenu';
import ProfileAvatar from '../profileAvatar';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (p) => !['elevation'].includes(p),
})(({ theme, elevation }) => ({
  position: 'relative',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  boxShadow: elevation > 0 ? (
    `0px ${1 * elevation}px ${8 * elevation}px 0px rgba(0, 0, 0, 0.12)`
  ) : undefined,
  '& .MuiToolbar-root': {
    minHeight: '72px',
  },
}));

const RelocNavGrid = styled(Grid, {
  shouldForwardProp: (p) => !['location', 'relocation'].includes(p),
})(({
  theme,
  location,
  relocation,
}) => ({
  display: 'none',
  ...Object.fromEntries(Object.entries(relocation).map(([bk, loc]) => ([
    theme.breakpoints.up(bk),
    { display: loc === location ? 'block' : 'none' },
  ]))),
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '28px',
}));

const PortraitMenuButton = styled(Button)(({ theme }) => ({
  '& .MuiButton-root': {
    borderRadius: '28px',
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[100],
    padding: '8px',
  },
}));

export default function HeaderBar() {
  const {
    elevation,
    navLinks,
    isAuthenticated,
    handleOpenMenu,
  } = useContext(HeaderContext);
  return (
    <StyledAppBar elevation={elevation}>
      <Toolbar>
        <Grid
          container
          flexWrap='nowrap'
          paddingLeft={{ xs: 0, md: 11.75 }}
          paddingRight={{ xs: 0, md: 11.75 }}
          spacing={3}
        >
          <Grid item display='flex' alignItems='center'>
            <Link href='/'>
              <MmmLogo size={24} />
            </Link>
          </Grid>
          <Grid item xs>
            <Grid
              container
              spacing={{ xs: 0, sm: 1 }}
              alignItems='center'
              flexWrap='nowrap'
            >
              <Grid item xs>
                <Grid container flexWrap='nowrap'>
                  <Grid item xs>
                    <Grid container flexWrap='nowrap'>
                      {
                        Object.entries(navLinks)
                          .filter(([, { auth = () => true }]) => auth(isAuthenticated))
                          .map(([key, { relocation, label, button }]) => (
                            <RelocNavGrid
                              item
                              key={`reloc-main-${key}`}
                              location='main'
                              relocation={relocation}
                            >
                              <NavButton location='main' {...button}>
                                {label}
                              </NavButton>
                            </RelocNavGrid>
                          ))
                      }
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container flexWrap='nowrap'>
                      {
                        Object.entries(navLinks)
                          .filter(([, { auth = () => true }]) => auth(isAuthenticated))
                          .map(([key, { relocation, label, button }]) => (
                            <RelocNavGrid
                              item
                              key={`reloc-alt-${key}`}
                              location='alt'
                              relocation={relocation}
                            >
                              <NavButton location='alt' {...button}>
                                {label}
                              </NavButton>
                            </RelocNavGrid>
                          ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!isAuthenticated && (
                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <SignInSignUp />
                </Grid>
              )}
              <Grid item>
                {isAuthenticated ? (
                  <PortraitMenuButton onClick={handleOpenMenu} aria-label='Global Menu'>
                    <Grid container flexWrap='nowrap' spacing={2}>
                      <Grid item display='flex' alignItems='center'>
                        <ProfileAvatar size={32} />
                      </Grid>
                      <Grid item display='flex' alignItems='center'>
                        <StyledMenuIcon />
                      </Grid>
                    </Grid>
                  </PortraitMenuButton>
                ) : (
                  <IconButton
                    onClick={handleOpenMenu}
                    aria-label='Global Menu'
                  >
                    <StyledMenuIcon />
                  </IconButton>
                )}
                <HeaderDrawer />
                <HeaderMenu />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}
