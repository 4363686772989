import isEmptyish from '@tmap/mmm-core/isEmptyish';

/**
 * Check if an incentive could use common app to apply.
 * Does not care if the status is `inactive` (Closed).
 */
const isCommonAppIncentive = (incentive) => (
  !isEmptyish(incentive?.offerId)
  && incentive?.disableCommonApp !== true
);

export default isCommonAppIncentive;
