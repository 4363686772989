import React, { useMemo } from 'react';
import { parseISO } from 'date-fns/parseISO';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { PinDrop } from '@tmap/mmm-style-guide/src/Icon';
import dateDifference from '@tmap/mmm-core/dateDifference';
import { badges } from '@tmap/mmm-style-guide/src/badges';
import ConditionalLazyLoad from '../conditional-lazy-load';
import { Portrait } from './portrait';
import sentenceCase from '../../lib/sentenceCase';

const TruncatedText = styled(Typography)(() => ({
  fontWeight: 500,
  mt: 0.25,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.14px',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
}));

const ProfileGrid = styled(Grid)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(0.5),
}));

const BylineGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
}));

const BylineText = styled(Typography, {
  shouldForwardProp: (p) => p !== 'colors',
})(({ theme, colors }) => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  lineHeight: '20px',
  letterSpacing: '0.14px',
  ...(colors ? {
    color: colors(theme).title,
  } : {}),
}));

const FlexGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
}));

const PinIcon = styled(PinDrop)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.veryLight,
  padding: theme.spacing(1),
  borderRadius: '50%',
  fontSize: '32px',
}));

const NoOverflowGrid = styled(Grid)(() => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
}));

const ShowGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'visibleBreakpoint',
})(({ theme, visibleBreakpoint }) => ({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  display: 'none',
  [visibleBreakpoint(theme)]: {
    display: 'flex',
  },
}));

function BadgeTitlesGridItem(props) {
  const {
    Badges = [],
    visibleBreakpoint = () => true,
    gridItemProps = {},
    maxBadges = Badges.length,
  } = props;

  return Badges.length > 0 ? (
    <ShowGrid item visibleBreakpoint={visibleBreakpoint} {...gridItemProps}>
      <NoOverflowGrid container flexWrap='nowrap' columnSpacing={0.5}>
        {Badges.slice(0, maxBadges)}
        {Badges.length > maxBadges && (
          <BylineGrid item>
            <BylineText variant='body2' color='grey.600'>
              +{Badges.length - maxBadges} badge{Badges.length - maxBadges > 1 ? 's' : ''}
            </BylineText>
          </BylineGrid>
        )}
      </NoOverflowGrid>
    </ShowGrid>
  ) : null;
}

export function CardProfile(props) {
  const {
    author = {},
    size = 32,
    contribution,
    datePrefix = '',
    showDate,
    showLocationPin = false,
    hideBadgeIcon = false,
    maxBadges: maxBadgesProp = {},
    lazyProps = {},
    hideAuthorLocation = false,
  } = props;
  const {
    shouldLazyLoad = false,
    ...otherLazyProps
  } = lazyProps;
  const maxBadges = { desktop: 3, mobile: 1, ...maxBadgesProp };

  const names = [];
  if (author.firstName) {
    names.push(author.firstName);
  }
  if (author.lastName?.trim()?.charAt(0)?.match(/^[a-z]$/i)) {
    names.push(author.lastName.charAt(0));
  }
  const parsedDate = parseISO(contribution.createdAt || '');
  const nameString = names.map((n) => sentenceCase(n).trim()).join(' ') || '-';
  const difference = dateDifference(parsedDate);
  const dateString = `${datePrefix} ${difference === 'Today' ? 'Today' : `${difference} ago`}`;

  const Badges = useMemo(() => {
    const filteredBadges = (author.badges || []).filter((b) => badges[b] && !badges[b].disabled);
    return filteredBadges.map((b, i) => {
      const badge = badges[b];
      return (
        <BylineGrid item key={`badge-title-${b}-${i}`}>
          <BylineText variant='body2' fontWeight={500} colors={badge.colors}>
            {badge.title}{i < filteredBadges.length - 1 ? ',' : ''}
          </BylineText>
        </BylineGrid>
      );
    });
  }, [author.badges]);

  return (
    <Grid
      container
      direction='row'
      flexWrap='nowrap'
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid item>
        <ProfileGrid
          container
          alignItems='center'
          columnSpacing={1.5}
        >
          {(author.profileImage?.asset || author.profileImage?.assetId || author.badges?.length > 0)
            && (
              <Grid item>
                <ConditionalLazyLoad shouldLazyLoad={shouldLazyLoad} {...otherLazyProps}>
                  <Portrait
                    author={author}
                    image={author.profileImage}
                    size={size}
                    hideBadgeIcon={hideBadgeIcon}
                  />
                </ConditionalLazyLoad>
              </Grid>
            )}
          <NoOverflowGrid item xs>
            <NoOverflowGrid
              container
              direction={Badges.length > 0 ? 'column' : 'row'}
              alignItems={Badges.length > 0 ? 'start' : 'center'}
              columnSpacing={1}
            >
              <NoOverflowGrid item maxWidth='100% !important'>
                <NoOverflowGrid container columnSpacing={0.75}>
                  {nameString && (
                    <BylineGrid item>
                      <BylineText variant='body2' fontWeight={500}>
                        {nameString}
                      </BylineText>
                    </BylineGrid>
                  )}
                  <BadgeTitlesGridItem
                    Badges={Badges}
                    visibleBreakpoint={(theme) => theme.breakpoints.up('md')}
                    maxBadges={maxBadges.desktop}
                  />
                  <BadgeTitlesGridItem
                    Badges={Badges}
                    visibleBreakpoint={(theme) => theme.breakpoints.down('md')}
                    maxBadges={maxBadges.mobile}
                  />
                </NoOverflowGrid>
              </NoOverflowGrid>
              <NoOverflowGrid item>
                <NoOverflowGrid container alignItems='center'>
                  {(showDate || (!hideAuthorLocation && author.location)) && (
                    <FlexGrid item>
                      <TruncatedText component='div' color='grey.600' variant='body2'>
                        {author.location}
                        {author.location && showDate && (<>&nbsp;&bull;&nbsp;</>)}
                        {showDate && dateString}
                      </TruncatedText>
                    </FlexGrid>
                  )}
                </NoOverflowGrid>
              </NoOverflowGrid>
            </NoOverflowGrid>
          </NoOverflowGrid>
        </ProfileGrid>
      </Grid>
      {showLocationPin && contribution.location && (
        <Grid item display='flex' alignItems='center'>
          <PinIcon />
        </Grid>
      )}
    </Grid>
  );
}
