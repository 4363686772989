import React from 'react';

import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Hyperlink } from '@tmap/mmm-style-guide/src/Hyperlink';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import {
  ArrowRightAlt, CardGiftcard, Favorite, ChatBubble, Settings, CheckBox, Notifications,
} from '@tmap/mmm-style-guide/src/Icon';

const HeaderText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '25.6px',
  letterSpacing: '0.15px',
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  lineHeight: '20.2px',
  marginTop: theme.spacing(1),
}));

const ActiveOffersContainer = styled(Box)(({ theme }) => ({
  padding: '28px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.primary.veryLight,
  height: '100%',
}));

const CTAChipContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[50],
  margin: 0,
}));

const CTAChip = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1),
  height: theme.spacing(4),
  display: 'flex',
  whiteSpace: 'nowrap',
}));

function ActiveOffersCTA() {
  return (
    <ActiveOffersContainer>
      <Grid
        container
        flexDirection='column'
        justifyContent='space-between'
        sx={{ height: '100%' }}
      >
        <Grid item>
          <CTAChipContainer container spacing={1}>
            <Grid item>
              <CTAChip>
                <CardGiftcard fontSize='14px' sx={{ color: (t) => t.palette.info.light, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Stay on top of your Program progress
                </Typography>
              </CTAChip>
            </Grid>
            <Grid item>
              <CTAChip>
                <Favorite fontSize='14px' sx={{ color: (t) => t.palette.favorite.light, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Review your favorites
                </Typography>
              </CTAChip>
            </Grid>
            <Grid item>
              <CTAChip>
                <ChatBubble fontSize='14px' sx={{ color: (t) => t.palette.warning.light, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Revisit saved content
                </Typography>
              </CTAChip>
            </Grid>
            <Grid item>
              <CTAChip>
                <Settings fontSize='14px' sx={{ color: (t) => t.palette.success.main, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Fine-tune your profile and settings
                </Typography>
              </CTAChip>
            </Grid>
            <Grid item>
              <CTAChip>
                <CheckBox fontSize='14px' sx={{ color: (t) => t.palette.green.main, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Tackle your tasks
                </Typography>
              </CTAChip>
            </Grid>
            <Grid item>
              <CTAChip>
                <Notifications fontSize='14px' sx={{ color: (t) => t.palette.blue.dark, opacity: 0.5, mr: 1 }} />
                <Typography fontSize='10px' color='text.disabled'>
                  Set communication preferences
                </Typography>
              </CTAChip>
            </Grid>
          </CTAChipContainer>
        </Grid>
        <Grid item sx={{ marginTop: 3 }}>
          <Hyperlink href='/myprofile/offers' sx={{ display: 'flex', alignItems: 'center' }}>
            <HeaderText color='textPrimary' sx={{ display: 'inline' }}>
              My Workspace
            </HeaderText>
            <ArrowRightAlt color='secondary' sx={{ ml: 0.5 }} />
          </Hyperlink>
          <DescriptionText variant='body2' fontWeight={400} color='textSecondary'>
            One click to your Workspace — manage everything you need in one place.
          </DescriptionText>
        </Grid>
      </Grid>
    </ActiveOffersContainer>
  );
}

export default ActiveOffersCTA;
