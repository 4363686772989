import React from 'react';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ArrowForward } from '@tmap/mmm-style-guide/src/Icon';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import Link from 'next/link';
import { DARK_THEME } from '../lib/themes';
import Header from '../components/header';
import Footer from '../components/footer';

const toPalette = (theme, color) => ({
  veryDim: theme.palette.grey[50],
  light: theme.palette.common.white,
  dim: theme.palette.grey[100],
  dark: theme.palette.grey[900],
  primary: theme.palette.primary.main,
  primaryDark: theme.palette.primary.dark,
  primaryVeryDark: theme.palette.primary.veryDark,
}[color] || color);

const overflowStyles = (theme, breakpoint) => {
  const style = {
    overflow: 'hidden',
    '& .swiper': {
      overflow: 'visible',
      width: '100%',
    },
  };
  if (breakpoint === true) return style;
  if (breakpoint) return { [theme.breakpoints.down(breakpoint)]: style };
  return {};
};

const gradientBackground = (theme, color, fadeTo, fadeDistance = 0, fadeOffset = 50) => {
  const colorVal = toPalette(theme, color);
  const fadeToVal = toPalette(theme, fadeTo);
  return `linear-gradient(180deg, ${colorVal} 0% ${fadeOffset - fadeDistance}%, ${fadeToVal} ${fadeOffset + fadeDistance}% 100% )`;
};

const BlocksRow = styled(Box, {
  shouldForwardProp: (p) => !['color', 'fadeTo', 'fadeDistance', 'fadeOffset', 'spacing', 'overflow'].includes(p),
})(({ theme, ...styleProps }) => {
  const {
    color,
    fadeTo,
    fadeDistance,
    fadeOffset,
    spacing = 'medium',
    overflow,
  } = styleProps;

  const ySpacing = {
    none: 0,
    small: 3,
    medium: 6,
    large: 9,
  }[spacing] || spacing;

  return ({
    background: (fadeTo ? (
      gradientBackground(theme, color, fadeTo, fadeDistance, fadeOffset)
    ) : (
      toPalette(theme, color)
    )),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(11),
    paddingTop: theme.spacing(ySpacing),
    paddingBottom: theme.spacing(ySpacing),
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '&': overflowStyles(theme, overflow),
  });
});
const BlocksContent = styled('div')(() => ({
  width: '100%',
  maxWidth: '1024px',
}));

function InheritTheme({ children }) {
  return children || null;
}

function DarkTheme({ children }) {
  return (
    <ThemeProvider theme={DARK_THEME}>
      {children}
    </ThemeProvider>
  );
}

function ColorBlocksBlock(props) {
  const {
    color,
    children,
    spacing,
    overflow,
    background,
    minHeight,
  } = props;
  const ThemeSwitcher = [
    'dark',
    'primary',
    'primaryDark',
    'primaryVeryDark',
  ].includes(color) ? DarkTheme : InheritTheme;
  return (
    <BlocksRow color={color} spacing={spacing} overflow={overflow} sx={{ background }}>
      <BlocksContent sx={{ minHeight }}>
        <ThemeSwitcher>
          {children}
        </ThemeSwitcher>
      </BlocksContent>
    </BlocksRow>
  );
}

function ColorBlocksInterstitial(props) {
  const {
    colorTop,
    colorBottom,
    colorPosition,
    theme = 'dark',
    spacing,
    children,
  } = props;

  const ThemeSwitcher = theme === 'dark' ? DarkTheme : InheritTheme;

  return (
    <BlocksRow spacing={spacing} color={colorTop} fadeTo={colorBottom} fadeOffset={colorPosition}>
      <BlocksContent>
        <ThemeSwitcher>
          {children}
        </ThemeSwitcher>
      </BlocksContent>
    </BlocksRow>
  );
}

const HeaderWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },
}));
function ColorBlocksHeader(props) {
  const {
    children,
    buttonText,
    href,
    textAlign,
    component,
    onClick,
  } = props;

  return (
    <HeaderWrapper>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12} md>
          <Typography color='textPrimary' variant='h4' component={component} sx={{ fontSize: '28px', fontWeight: 700, textAlign }}>
            {children}
          </Typography>
        </Grid>
        {buttonText && (
          <Grid item>
            <Link href={href}>
              <Button variant='outlined' color='inverted' size='large' onClick={onClick} endIcon={<ArrowForward />}>
                {buttonText}
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </HeaderWrapper>
  );
}

function ColorBlocksSubHeader(props) {
  const { children, textAlign } = props;
  return (
    <Typography variant='body1' color='textSecondary' sx={{ marginTop: { xs: 1, lg: 1.5 }, textAlign }}>
      {children}
    </Typography>
  );
}

function ColorBlocks(props) {
  const { children, footerTheme, headerElevation = 0 } = props;
  return (
    <>
      <Header elevation={headerElevation} />
      {children}
      <Footer theme={footerTheme} />
    </>
  );
}

ColorBlocks.Block = ColorBlocksBlock;
ColorBlocks.Interstitial = ColorBlocksInterstitial;
ColorBlocks.Header = ColorBlocksHeader;
ColorBlocks.SubHeader = ColorBlocksSubHeader;

export default ColorBlocks;
