import React from 'react';
import NavListItem from '@tmap/mmm-style-guide/src/NavListItem';
import Link from 'next/link';

const LinkedMenuItem = React.forwardRef((props, ref) => {
  const {
    href, children, ...otherProps
  } = props;
  return (
    <Link href={href} tabIndex={-1}>
      <NavListItem {...otherProps} ref={ref}>{children}</NavListItem>
    </Link>
  );
});

export default LinkedMenuItem;
